import { Link } from 'gatsby';
import React, { useState } from 'react';
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg';
import BuildMVP from '../../templates/buildMVP';
import EnhanceComponent from './enhanceComponent';
import MVPProcessComponent from './mvpProcessComponent';
import SassDesignImage from '../../images/sassDesign.png';
import StrategyImage from '../../images/sassStrategy.png';
import SassDevelopementImage from '../../images/sassDevelopement.png';
import LaunchImage from '../../images/sassLaunchImage.png';
import AnalysisImage from '../../images/analysisImage.png';
import ProductIdeaIamge from '../../images/productIdeaImage.png';
import DesignProductIdea from '../../images/designPropertypeImage.png';
import ImplementationImage from '../../images/implementationImage.png';
import SupportImage from '../../images/supportImage.png';
import RequiremantImage from '../../images/frontBackRequirementsImage.png';
import EstimationImage from '../../images/backFrontEstimation.png';
import DevelopementFrontBack from '../../images/backFrontDevelopement.png';
import SupportFrontBack from '../../images/supportFrontBack.png';
import DeliveryFrontBack from '../../images/deliveryFrontBack.png';
import FrontBackImage from '../../images/frontBackImage.png';
import CustomDefination from '../../images/customDefination.png';
import CustomDesignImage from '../../images/customDesign.png';
import CustomDevelopment from '../../images/customDevelopement.png';
import CustomDeliveryImage from '../../images/customDelivery.png';
import LetsTalkComponent from './letsTalkComonent';
const WebApplicationServicesComponent = props => {
  const {
    webApplicationDeveloperTitle,
    webApplicationDeveloperDescription,
    saasMvpTitle,
    saasMvpDescription,
    enhanceSaasTitle,
    enhanceSaasDescription,
    frontendTitle,
    frontendDescription,
    backendTitle,
    backendDescription,
    customTitle,
    customDescription,
    progressiveWebTitle,
    progressiveWebDescription,
  } = props;
  const { webApplicationButton } = props;
  const [isActive, setisActive] = useState(0);
  return (
    <div className='saas-section'>
      <div className='full-container'>
        <div className='saas-section-description'>
          <div className='home-section-textIcone'>
            <TitleIcone className='home-section-titleIcone' />
            <h2 className='challenge-section-title'>
              {webApplicationDeveloperTitle}
            </h2>
          </div>
          <p className='saas-section-description-text'>
            {webApplicationDeveloperDescription}
          </p>
        </div>
      </div>
      <div className='service-mobile-application-section-listSection'>
        <ul className='service-mobile-application-section-list'>
          {webApplicationButton &&
            webApplicationButton.map((item, index) => {
              return (
                <li key={index}>
                  <div
                    onClick={() => {
                      setisActive(index);
                    }}
                    className={`${
                      index === isActive
                        ? 'saas-section-wrapper-active'
                        : 'saas-section-wrapper'
                    }`}
                  >
                    <p
                      className={`${
                        index === isActive
                          ? 'saas-section-wrapper-title-active'
                          : 'saas-section-wrapper-title'
                      }`}
                    >
                      {item.title}
                    </p>
                    {/* <p className="saas-section-wrapper-description">{item.description}</p> */}
                    {/* <button className="saas-section-wrapper-link" onClick={() => {setisActive(index);}}>{item.button}</button> */}
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      {isActive === 0 && (
        <div>
          <MVPProcessComponent
            MVPTitle='Analysis, Research & Preparation'
            MVPDescription='We listen to understand what you want to build, what features your product should have, and what purpose it will serve.'
            sassMvpText='Our teams of experienced product development managers are ready to partner closely with you to successfully define, build, and evolve compelling, high-performing product solutions in a variety of shapes and sizes.'
            sassMvpDescription='We collaborate from the start to analyze and identify opportunities, develop your concepts, and prepare to build your digital product.
                        Our analysis and research will help you identify your product’s strengths, weaknesses, opportunities, and threats and how to minimize risk while maximizing growth.'
            isSassMVP={true}
            sassMvpImage={AnalysisImage}
          />
          <MVPProcessComponent
            MVPTitle='Conceptualizing and Testing the Product Idea'
            MVPDescription='In order to conceptualize a new idea and advance it through the various stages of innovation, it is crucial to focus thoughts on particular dimensions and look for answers to particular concerns.'
            sassMvpText='Transforming a product from concept to reality can be time-consuming and expensive, which is why we at UpTech Sh.P.K through user research and tests, define how the final product will function and look so users get value effortlessly and enjoy using it.'
            sassMvpDescription='We draw a final view of the software, honing in on details and micro-interactions, and give our clients the chance to conceptualize the product, keeping them involved in the process in order to make a harmonic design of the product, integrating their brand language and marketing strategies.'
            isSassMVP={true}
            isLeft={true}
            isTesting={true}
            sassMvpImage={ProductIdeaIamge}
          />
          <MVPProcessComponent
            MVPTitle='Designing and Prototyping Your Product Idea'
            MVPDescription='You don’t really have a product if you haven’t designed it yet. The design process is a series of stages that product teams adhere to during the whole creation of a product.
                        Refine your product’s design and functionality, eliminate the risk of failure, and validate your product in your target customer segment by bringing it into action through our software prototype development services.'
            sassMvpText='The prototype will visually trace the user journey and allows you to test functionalities, features, and underlying tech integrations. By making your idea tangible, software prototyping enables risk analysis, accelerates cycle times, and helps create quality solutions. Vision encapsulates the core of the product and the vital knowledge the product team has to have in order to create and introduce a successful product.'
            sassMvpDescription='  UpTech Sh.P.K will help you quickly test and validate a working prototype for the customer experience, UI, UX, and business use case to raise funding and develop your product with confidence.'
            isSassMVP={true}
            sassMvpImage={DesignProductIdea}
          />
          <MVPProcessComponent
            MVPTitle='Agile Implementation'
            MVPDescription='Using an Agile project management methodology significantly increases development productivity, gives you more control, and results in an end product that provides real value to your users.
            UpTech Sh.P.K splits your project into development phases known as sprints, with working software increments delivered at the end of each sprint.'
            sassMvpText='Different from traditional project management, agile software development, and project management require iterations and feedback at every stage.'
            sassMvpDescription='Our developers will craft flexible and lighting-fast interfaces to provide your users with a picture-perfect experience, while our proven Agile product development methods will streamline the processes to help you create a robust, well-thought-out product that will thrive in its target market.'
            isSassMVP={true}
            isLeft={true}
            isTesting={true}
            sassMvpImage={ImplementationImage}
          />
          <MVPProcessComponent
            MVPTitle='Maintenance & Support'
            MVPDescription='Once your product is out, we will support its growth by setting up continuous integration and delivery to increase deployment capacity.'
            sassMvpText='Our experts will sustain the seamless functioning of your software product and swiftly resolve any issues before they reach the end-users.
                        Furthermore, we provide continuous software improvement and customization services to help you drive maximum value from the product.'
            sassMvpDescription='And with our upgrade and monitoring services, we ensure that there is zero or minimal downtime in your environment, and all your platform upgrades are done timely.
                        With specialized support and maintenance services, we raise the value of your current product.'
            isSassMVP={true}
            sassMvpImage={SupportImage}
          />
          <LetsTalkComponent />
        </div>
      )}
      {isActive === 1 && (
        <div className='enhance-section-background'>
          <MVPProcessComponent
            MVPTitle='Strategy'
            MVPDescription='The global SaaS application market is strictly competitive and fragmented.
                              To create a successful SaaS application, a detailed understanding of the market is necessary. Therefore, we carry out a thorough analysis of competitors, what the market currently requires, the target audience, and monetization models.'
            //   sassMvpText="Having a great idea means first understanding what the market needs and resorting to the perfect business strategy. This step is important because having a great idea in the first place will save us a lot of time and money."
            isSassMVP={true}
            isLeft={true}
            isEnhance={true}
            sassMvpImage={StrategyImage}
          />
          <EnhanceComponent
            enhanceTitle='Design'
            enhanceDescription='Prototyping our SaaS Application requires inputting prime features and architectural models waiting to be developed. Based on the client’s requirements and preferences we will also decide whether we will go for a single-tenant or a multi-tenant architecture. '
            enhanceImage={SassDesignImage}
            enhanceDescriptionText='We start chalking out all the relevant implementations and selecting the proper database for a successful SaaS application development.'
          />
          <MVPProcessComponent
            MVPTitle='Development'
            MVPDescription='We have reached the core part of the process where other tasks like choosing the model or type of development, tech stack, and overall method are needed as we create an MVP. '
            sassMvpImage={SassDevelopementImage}
            sassMvpText='We will work closely with you in making productive decisions on the front-end and back-end development process, seamless and easy migrations, and that every implementation is working perfectly together. '
            isLeft={true}
            isEnhance={true}
            isSassMVP={true}
          />
          <EnhanceComponent
            enhanceTitle='Launch'
            enhanceDescription='Launching a SaaS Application includes three stages, pre-launch, launch, and post-launch. During pre-launch, we will make sure to get the product ready.'
            enhanceImage={LaunchImage}
            enhanceDescriptionText='The launch stage includes selecting the right marketing strategy. The post-launch is all about user onboarding. Using checklists, welcome screens or interactive product walkthroughs will surely give us the right idea of feedback.
                        We have developed SaaS Applications in different areas like CRM, billing, project managing, HR, E-commerce, etc.'
          />
          <LetsTalkComponent />
        </div>
      )}
      {isActive === 2 && (
        <div className='enhance-section-background'>
          <MVPProcessComponent
            MVPTitle='Requirements'
            MVPDescription="We will help you define the core functionalities of your application or website. We'll define the needs for your product, which may include a business need, technical need, or both."
            sassMvpText='This essential step will provide you with an understanding of what features are required so that you can work with the business consultants and software architects to build a plan that meets your needs.'
            isEnhance={true}
            isLeft={true}
            isSassMVP={true}
            sassMvpImage={RequiremantImage}
          />
          <MVPProcessComponent
            MVPTitle='Estimation'
            MVPDescription='Once the requirements are collected, it is time to create a prototype. Our prototype will manifest the basic idea of the future application helping us have a clearer view of what additional features will be included for an innovative product.'
            sassMvpText='We will make sure the end product is on the right track by continuously basing our taken actions on a product that represents market and customers needs.'
            isTesting={true}
            isEnhance={true}
            isSassMVP={true}
            sassMvpImage={EstimationImage}
          />
          <MVPProcessComponent
            MVPTitle='Development'
            MVPDescription='Our experience enables us to deliver software products that meet your needs and expectations. We are excited to work with you to develop the next great software product.'
            sassMvpText='To ensure that the product is created in the most effective manner, our frontend developers identify the finest front-end frameworks, tools, and development techniques, while our back-end developers will focus on delivering server-side software code, setting up databases and resolving code bugs.'
            isEnhance={true}
            isLeft={true}
            isSassMVP={true}
            sassMvpImage={DevelopementFrontBack}
          />
          <MVPProcessComponent
            MVPTitle='Delivery'
            MVPDescription='Our frontend developers raise the bar for excellence-speed, operational efficiency, reliability, and security all in a well-round product while ensuring the defects will not get in the way of successful delivery. '
            sassMvpText='Our backend developers will offer greater product quality, making sure it has exceeded the client’s expectations has a scalable architecture and is now an ambitious and innovative addition to the technology world.'
            isTesting={true}
            isEnhance={true}
            isSassMVP={true}
            sassMvpImage={DeliveryFrontBack}
          />
          <MVPProcessComponent
            MVPTitle='Support'
            MVPDescription='UpTech’s Sh.P.K team will provide post-operation support so that the application never experiences issues, runs slowly, or crashes. They will optimize the code, and databases, be available to fix bugs that may occur or be able to implement new features that can later be required and necessary for the success of the application. We aim for consistent, unproblematic performance.'
            isEnhance={true}
            isLeft={true}
            isSassMVP={true}
            sassMvpImage={SupportFrontBack}
          />
          <MVPProcessComponent
            MVPTitle='Our Front-end / Back-end tech stackry'
            MVPDescription="Our tech stack incorporates the latest technologies, tools, and frameworks. 
                                Almost any software infrastructure's success is largely dependent on front-end technologies. 
                                Our front-end developers are highly experienced in HTML, CSS, Responsive Web design, React JS, Node JS, Javascript, PSD to BootStrap, and CMS services."
            sassMvpText='The backend development remains invisible as it provides the ‘backbone’ of the application. 
                                We pride ourselves in being experts on programming languages, such as Ruby and Python; libraries and frameworks such as Ruby on Rails and Django; JavaScript and Node.js.'
            isEnd={true}
            isEnhance={true}
            // isLeft={true}
            // isFrontEnd={true}
            // isTesting={true}
            isSassMVP={true}
            sassMvpImage={FrontBackImage}
          />
          <LetsTalkComponent />
        </div>
      )}
      {isActive === 3 && (
        <div className='enhance-section-background'>
          <MVPProcessComponent
            MVPTitle='Definition'
            MVPDescription='One of the most important processes of creating a customized app is brainstorming with our clients on ideas that can lead to something great. We will develop numerous meetings to better understand where are you coming from, and what you want to achieve and offer our guidance as well.'
            sassMvpText='Having a great idea means first understanding what the market needs and resorting to the perfect business strategy.  This step is important because having a great idea in the first place will save us a lot of time and money.'
            isSassMVP={true}
            isLeft={true}
            isEnhance={true}
            sassMvpImage={CustomDefination}
          />
          <EnhanceComponent
            enhanceTitle='Design'
            enhanceDescription='Now it is time to create the perfect prototype. The first taste of our customized product will strictly have the core functionalities and the well-tailored features that were specifically laid down in the first step.'
            // enhanceImage=""
            enhanceDescriptionText='We will do a deep dive into the future users, their roles, how they will use this application, and how we can make it even more innovative and helpful. 
                          UpTech’s Sh.P.K  development team will make sure to offer a new experience in using our customized application thanks to our expertise and elaborate market research.'
            enhanceImage={CustomDesignImage}
          />
          <MVPProcessComponent
            MVPTitle='Development'
            MVPDescription='Our experienced programmers now come in handy as they take our pencil and paper concept and turn it into something tangible. 
                          They will decide on the right programming languages, frameworks, and databases always keeping in mind the special requirement this customized application needs.'
            //   MVPBuildImage=""
            sassMvpText='The MVP created will serve as a stepping stone for further development. 
                          We develop several meetings to make sure the development team stays on track timewise avoiding backlogs and being in control.'
            isLeft={true}
            isEnhance={true}
            isSassMVP={true}
            sassMvpImage={CustomDevelopment}
          />
          <EnhanceComponent
            enhanceTitle='Delivery'
            enhanceDescription='A thorough quality assurance (QA) and review are essential parts of the development process, and we never cut corners on this important step. 
                        Our team will test the web app on a variety of devices, browsers, and in different situations so that you can be confident that your custom app will perform its job flawlessly. Once all the details are finalized, and you are satisfied with the software we can finally launch it.'
            // enhanceImage=""
            enhanceDescriptionText='With our progressive business strategy and marketing, we will reach a large audience as we make sure our product remains fresh and provides continuity of efficient app functionality.'
            enhanceImage={CustomDeliveryImage}
          />
          <LetsTalkComponent />
        </div>
      )}
      {/* <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="saas-section-wrapper">
                        <p className="saas-section-wrapper-title">{saasMvpTitle}</p>
                        <p className="saas-section-wrapper-description">{saasMvpDescription}</p>  
                        <Link class="saas-section-wrapper-link" to="/build-your-saas-mvp">Learn More</Link>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                    <div className="saas-section-wrapper">
                    <p className="saas-section-wrapper-title">{enhanceSaasTitle} </p>
                        <p className="saas-section-wrapper-description">{enhanceSaasDescription} </p>  
                        <Link class="saas-section-wrapper-link" to="/enhance-your-saas">Learn More</Link>
                    </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="saas-section-wrapper">
                    <p className="saas-section-wrapper-title">{frontendTitle}</p>
                        <p className="saas-section-wrapper-description">{frontendDescription} </p>  
                        <Link class="saas-section-wrapper-link" to="/frontend-development">Learn More</Link>
                    </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 ">
                    <div className="saas-section-wrapper">
                    <p className="saas-section-wrapper-title">{backendTitle}</p>
                        <p className="saas-section-wrapper-description">{backendDescription} </p>  
                        <Link href="#" class="saas-section-wrapper-link" to="/backend-development">Learn More</Link>
                    </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                    <div className="saas-section-wrapper">
                        <p className="saas-section-wrapper-title"> {customTitle}</p>
                        <p className="saas-section-wrapper-description"> {customDescription}</p>  
                        <Link href="#" class="saas-section-wrapper-link" to="/custom-development">Learn More</Link>
                    </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                    <div className="saas-section-wrapper">
                    <p className="saas-section-wrapper-title"> {progressiveWebTitle}</p>
                        <p className="saas-section-wrapper-description">{progressiveWebDescription} </p>  
                        <Link href="#" class="saas-section-wrapper-link" to="/web-app-development">Learn More</Link>
                    </div>
                    </div>
                </div> */}

      {/* <div className="saas-section-button">
                    <Link className="saas-section-button-text" to="/contact">Contact Us</Link>
                </div> */}
    </div>
  );
};

export default WebApplicationServicesComponent;

import React from "react";
import WebApplicationServicesComponent from "../component/Services/webApplicationServicesComponent";
import ServicesImage from '../images/services_banner-min.jpg'
import ServicesHomeComponent from "../component/Services/servicesHomeComponent";
import { graphql } from 'gatsby';
import FooterComponent from '../component/footerComponent'

const webApplicationButton = [
  {
    title:"Build your SaaS MVP",
    // description:"Our SaaS experts will build multi-tenant, secure, and robust SaaS solutions to serve your customers better. They are aimed at helping you create software applications for web platforms, based on your specific needs.",
    button:'Learn More'
  },
  {
    title:"Enhance your SaaS",
    // description:"There are numerous ways to grow your business, but we will help you sort through the clutter to figure out what will sustain your growth rate. We focus on what the customers want and turn that into an innovative product.",
    button:'Learn More'
  },
  {
    title:"Frontend / Backend Development",
    // description:'While our frontend developers take the vision and design concept from client to code, our backend developers will design a solid backend to accommodate scale, flexibility, and cutting-edge security.',
    button:'Learn More'

  },
  {
    title:'Custom development',
    // description:'In custom development, we will help you sort through the clutter by building web-based apps that run faster, deliver a better user experience, excel in process management systems, and increase mobile conversion rates.',
    button:'Learn More'

  }
]
const SassWebApplication = (props) =>{
    const {
        data:{
            wpPage:{
                serviciesHeader: {
                    servicesHomeTitle,
                    servicesHomeDescription,
                  },
            }
        }
    } = props;
    return(
        <>
        <ServicesHomeComponent
          servicesBackground={ServicesImage}
          servicesHomeTitle={servicesHomeTitle}
          servicesDescription={servicesHomeDescription}
        />
         <WebApplicationServicesComponent
         webApplicationDeveloperTitle="Web Application Development Offerings:"
         webApplicationDeveloperDescription=" We’ll prototype, design, build and launch digital experiences for all devices (mobile and desktop). Our 
         team utilizes the latest technology to build applications that attract, engage, and convert your particular 
         audience."
         webApplicationButton={webApplicationButton}
        //  saasMvpTitle="Build your SaaS MVP"
        //  saasMvpDescription="Our SaaS experts will build 
        //  secure and robust SaaS solutions to 
        //  serve your customers. Their 
        //  aim is to helping you create software applications for web platforms."
        //  enhanceSaasTitle="Enhance your SaaS"
        //  enhanceSaasDescription=" There are numerous ways to grow 
        //  your business, but we will help you 
        //  sort through the clutter to figure out 
        //  a few things that will change and 
        //  sustain your growth rate."
        //  frontendTitle="Frontend Development"
        //  frontendDescription="There are numerous ways to grow your business,
        //   but we will help you sort through the clutter 
        //   to figure out a few things that will 
        //   change and sustain your growth rate."
        //  backendTitle="Backend Development"  
        //  backendDescription="Our software architects design solid 
        //  backend to accommodate scale, 
        //  flexibility to integrate, advanced 
        //  data structures, and cutting-edge 
        //  security."
        //  customTitle="Custom development"
        //  customDescription=" Get a performance boost across the 
        //  organization by building web-based 
        //  apps, learning, and process 
        //  management systems. "
        //  progressiveWebTitle="Progressive Web Apps Development"
        //  progressiveWebDescription=" We develop progressive web apps 
        //  using brand-new technologies, that 
        //  offer optimal performance and ensure 
        //  your applications and websites run 
        //  faster, deliver a better user 
        //  experience, and increase mobile 
        //  conversion rates."
         />
         <FooterComponent/>
         </>
    )

}
export const query = graphql`
  query {
    wpPage(slug: {eq: "web-application"}) {
        serviciesHeader {
            servicesHomeTitle
            servicesHomeDescription
          }
}
}
`;
export default SassWebApplication;